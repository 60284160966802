<template>
  <section class="project-detail clearfix container" v-if="project">
    <article class="float-left">
      <div class="basic-info clearfix">
        <div class="logo">
          <img
            v-if="project.img"
            :src="
              project_oss_link +
              project.img
            "
            width="96"
            height="96"
          />
          <img
            v-else
            src="@/assets/img/icon/default.png"
            width="96"
            height="96"
          />
        </div>
        <div class="float-left">
          <h4 class="dapp-title"><span>{{ project.title }}</span> <img v-if="project.isNew" src="@/assets/img/icon/new.png" height="16"> </h4>
          <div
            class="dapp-shortdes"
            v-html="webUtil.getFormatCode(project.introduction)"
          ></div>
          <div class="dapp-type">
            <label>{{ project.cateTitle }}</label>
            <template v-if="project.tags.length > 0">
              <label v-for="(item, index) in project.tags" :key="index"
                >{{ item }}
              </label>
            </template>
          </div>
        </div>
        <!-- 收藏 -->
        <div :class="['like-info',{active:isLiked}]" @click="likeAction">
          <img :src="require('@/assets/img/icon/favorites'+(isLiked?'':'_grey')+'.png')" width="24">
          <h6>{{voters?voters.length:'--'}}</h6>
          <p>{{$t('Favorite')}}</p>
        </div>
      </div>
      <!-- 价格曲线 -->
      <div
        class="kline-part"
        v-if="project.marketID>0"
      >
        <h2>
          <img
            :src="tokenImg"
            width="24"
            class="mr-6"
            @error="logoError"
          />
          <span>{{ project.token.name }}</span>
        </h2>
        <h2 v-if="project.token.price>0">
          <span class="mr-6">${{ project.token.price }}</span>
          <label v-if="project.token.percentChange < 0" class="color-down">{{project.token.percentChange }}%</label>
          <label v-else class="color-up">+{{ project.token.percentChange }}%</label>
        </h2>
        <a v-if="project.token.contractAddress" class="swap-link btn" :href="'https://pancakeswap.finance/swap?inputCurrency='+project.token.contractAddress" target="_blank" rel="noopener noreferrer">Trade</a>
        <KlinePart isDetail="1" :timeList="klineTimeList" :dataList="klinePriceList" v-if="klineTimeList&&klineTimeList.length>1"/>
        <ul class="select-kline-type" v-if="klineTimeList&&klineTimeList.length>1">
          <li
            v-for="item in klineType"
            :key="item.name"
            :class="{ active: selectedKline.name == item.name }"
            @click="selectKline(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <!-- tvl -->
      <div class="flex-between">
        <ul class="dev-active" v-if="project.dappradar">
          <li>
            <h6>{{$t('TotalValueLocked')}}</h6>
            <h2>{{project.dappradar.tvlShort?project.dappradar.tvlShort:'--'}}</h2>
          </li>
          <li>
            <h6>{{$t('address')}}</h6>
            <h2>{{project.dappradar.addressShort?project.dappradar.addressShort:'--'}}</h2>
          </li>
          <li>
            <h6>{{$t('txn')}}</h6>
            <h2>{{project.dappradar.txnShort?project.dappradar.txnShort:'--'}}</h2>
          </li>
        </ul>
        <div class="dev-active border" v-if="project.activity">
          <h6>{{$t('DevelopmentActivity')}}</h6>
          <h2>{{project.activity.commit30d}} <span class="text-sm">event (30d)</span> <label v-if="project.activity.commit30dChange < 0" class="color-down">{{webUtil.forPct(project.activity.commit30dChange)}}%</label><label v-else class="color-up">+{{webUtil.forPct(project.activity.commit30dChange)}}%</label></h2>
          <h2>{{project.activity.commit90d}} <span class="text-sm">event (90d)</span></h2>
          <sparkline v-if="project.list&&project.list.length>0" class="sparkline-part" :indicatorStyles="false" width="232" height="64">
            <sparklineBar
              :data="project.list"
              :margin="4"
              :limit="project.list.length"
              :styles="{fill: '#f0b90b'}"
              :refLineStyles="null"
            />
          </sparkline>
        </div>
      </div>
      <div class="about" v-if="project.description">
        <h2>{{$t('About')}} {{ project.title }}</h2>
        <div
          class="dapp-slogan"
          v-html="webUtil.getFormatCode(project.description)"
        ></div>
      </div>
    </article>
    <article class="float-right">
      <!-- 邮箱等链接 -->
      <div class="detail-contact">
        <p
          class="website btn"
          v-if="project.website"
          @click.stop="openLink(project.website)"
        >
          {{$t('VisitWebsite')}}
        </p>
        <ul>
          <li
            v-for="(item, i) in contactList"
            :key="i"
            @click.stop="openLink(project[item], item)"
          >
            <img :src="require('@/assets/img/tag/' + item + '.png')" />
            <span style="text-transform: capitalize">{{ item }}</span>
          </li>
        </ul>
        <a href="javascript:;" class="share-btn btn" @click="copyAction()">Share</a>
      </div>
      <!-- Security Score -->
      <!-- <div class="border-top">
        <h2>{{$t('SecurityScore')}} <span v-if="project.securityScore>0" class="fsz-16" :style="'color:'+getScoreColor()">{{project.securityScore}}</span></h2>
        <div class="progress"><span :style="'width:'+project.securityScore+'%;background:'+getScoreColor()"></span></div>
        <p><img :src="require('@/assets/img/icon/'+(project.audit?'audited':'unaudit')+'.png')" width="24" :class="{pointer:project.audit}" @click="goAudit"> <a class="text-black" href="https://www.avengerdao.org/" target="_blank" rel="noopener noreferrer">Powered by AvengerDAO</a></p>
      </div> -->
      <!-- 发布时间 -->
      <div class="border-top" v-if="project.publishDate">
        <h2>{{$t('PublishDate')}}</h2>
        <p class="item-list fsz-16">{{project.publishDate.slice(0,10)}}</p>
      </div>
      <!-- voters -->
      <div class="border-top" v-if="voters&&voters.length>0">
        <h2>{{voters.length}} {{$t('Favorites')}}</h2>
        <p class="voters-list">
          <a v-for="(address,i) in voterList" :key="i" target="_blank" rel="noopener noreferrer" :href="'https://bscscan.com/address/'+address">{{webUtil.formatStrByDot(address,8,8)}}</a>
          <a v-if="voters.length>10" href="javascript:;" @click="isFold=!isFold">{{isFold?'Get More':'Fold'}}</a>
        </p>
      </div>
    </article>
    <article class="float-left">
      <a
        :href="
          'mailto:bscproject@protonmail.com?subject=Update-' + project.title
        "
        class="update-project"
        >{{$t('SubmitThisProject')}}</a
      >
      <p class="color-grey tip">{{$t('projectAboutInfo')}}</p>
    </article>
  </section>
  <section v-else class="null">
    {{$t('NoInformation')}}
  </section>
</template>
<script>
import KlinePart from '../components/KlinePart.vue';
export default {
  components: { KlinePart },
  props: ["id"],
  data() {
    return {
      project: null,
      isLiked:false,
      contactList: [],
      klineTimeList:[],
      klinePriceList:[],
      klineType:[{
        type:'5min',
        name:this.$t('D'),
        limit:'288'
      },{
        type:'1h',
        name:this.$t('W'),
        limit:'168'
      },{
        type:'4h',
        name:this.$t('M'),
        limit:'180'
      }],
      selectedKline:{
        type:'5min',
        name:this.$t('D'),
        limit:'288'
      },
      USDRmbPrice:'6.48',
      voters:null,
      isFold: true,
    };
  },
  computed:{
    tokenImg(){
      if(this.project.token.img){
        let imgArr = this.project.token.img.split('token-logo/');
        if(imgArr&&imgArr.length>1&&imgArr[1]){
          return this.project.token.img
        }else{
          return ''
        }
      }
    },
    voterList(){
      return this.voters&&this.voters.length>0&&this.isFold?this.voters.slice(0,10):this.voters
    }
  },
  created() {
    this.getProjectInfo();
  },
  methods: {
    getProjectInfo() {
      if (this.id) {
        this.$loading(1);
        this.axios
          .get(this.domain + "getProjectById?v=1.0&id=" + this.id)
          .then((res) => {
            this.$loading(0);
            if (res.data.success) {
              this.project = res.data.data;
              this.contactList = this.contact
                .slice(1)
                .filter((v) => this.project[v]);

              if(this.project&&this.project.token){
                this.project.token.price = this.webUtil.BN(this.project.token.last2Rmb).div(this.USDRmbPrice).toFormat(8);
              }
              if(this.project.marketID&&this.project.marketID>0){
                this.getKlineData();
              }else{
                this.klineTimeList = null
              }
            }else{
              this.project=null;
            }
          })
          .catch((err) => {
            this.$loading(0);
            this.project = null;
            this.contactList = null;
            console.log(err);
          });
        this.getVoters();
      }
    },
    selectKline(item) {
      this.selectedKline = item;
      this.getKlineData();
    },
    getKlineData() {
      this.axios.get(this.marketDomain+"api/pricePoint?tokenID=" +
          this.project.marketID +
          "&type=" +
          this.selectedKline.type +
          "&limit=" +
          this.selectedKline.limit +
          "&baseCoin=USD").then(res=>{
          if (res.data.success && res.data.data.price) {
            var dataList = res.data.data.price.reverse();
            this.USDRmbPrice = res.data.data.baseCoin.rmb;
            this.project.token.price = this.webUtil.BN(
              this.project.token.last2Rmb
            ).div(this.USDRmbPrice).toFormat(8);
            this.klineTimeList = dataList.map((v) => v.time);
            this.klinePriceList = dataList.map((v) =>
              this.webUtil.BN(v.last2Rmb).div(this.USDRmbPrice).toFixed(4)
            );
          } else {
            this.klinePriceList = null;
            this.klineTimeList = null;
          }
        }).catch((err) => {
        // console.log(err);
      });
    },
    getVoters(){
      this.$loading(1);
      this.axios.get(this.domain+'getProjectStars?id='+this.id).then(res=>{
        this.$loading(0);
        if(res.data.success){
          this.voters = res.data.data;
          this.isLiked = this.voters&&this.account?this.voters.includes(this.account):false;
        }else{
          this.voters = null;
          this.isLiked = false;
        }
      }).catch(err=>{
        this.$loading(0);
        this.voters = null;
        this.isLiked = false;
      })
    },
    async likeAction(){
      let URL = this.domain+'bscProjectStar'
      if(this.isLiked){
        URL = this.domain+'bscProjectStarCancel';
      }
      let signResult = await this.sign();
      const qs = require('qs');
      this.axios.post(URL,qs.stringify({
        address:this.account,
        sign:signResult,
        message:this.signMessageStr,
        projectID:this.id
      })).then(res=>{
        if(res.data.success){
          this.getVoters();
          this.isLiked = !this.isLiked;
        }else{
          this.$loading(0);
        }
      }).catch(err=>{
        this.$loading(0);
        console.log(err);
      })
    },
    getScoreColor(){
      let s = this.project.securityScore;
      if(s<75){
        return '#F84960'
      }else if(s<85){
        return '#F0B90B'
      }else{
        return '#02c076'
      }
    },
    goAudit(){
      if(this.project.audit){
        window.open(this.project.audit,'_blank')
      }
    }
  },
  watch:{
    id(){
      this.getProjectInfo();
    },
    account(){
      this.getVoters();
    }
  }
};
</script>
<style scoped>
/*详情页*/
.project-detail h2 {
  font: bold 20px/24px Rubik-Medium;
  margin-bottom: 16px;
}
.float-left{
  width: 70%;
}
.float-right{
  width: 26%;
  max-width: 200px;
}
.basic-info {
  position: relative;
  padding: 0 0 var(--px48) 120px;
  border-bottom: var(--border);
  margin-bottom: var(--px48);
}
.basic-info .logo {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
}
.project-detail .dapp-title {
  word-break: break-word;
  white-space: normal;
  font-size: 24px;
  line-height: 28px;
}
.project-detail .dapp-slogan {
  display: block;
  word-break: break-word;
  font-size: 16px;
  line-height: 24px;
}
.project-detail .dapp-shortdes {
  color: #000;
  font-size: 14px;
  margin: 0;
}
.project-detail .dapp-type {
  display: flex;
  flex-wrap: wrap;
}
.project-detail .dapp-type label {
  padding: 4px 12px;
  border-radius: 16px;
  border:var(--border);
  margin: 8px 16px 0 0;
}
.detail-contact {
  padding-bottom: var(--px48);
}
.website,.share-btn{
  padding: 12px 24px 12px 52px;
  border-radius: 24px;
}
.website {
  background: var(--mainColor) url(../assets/img/icon/visit.png)no-repeat left 24px center/20px;
  color: #000;
  margin-bottom: 24px;
}
.share-btn {
  background: url(../assets/img/icon/share.png)no-repeat left 24px center/20px;
  color: var(--mainColor);
}
.detail-contact li {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-contact li img {
  width: 24px;
  margin-right: 8px;
}

.detail-contact li:last-child {
  margin-bottom: var(--px48);
}

.update-project {
  color: #000;
  text-decoration: underline;
  padding-bottom: var(--px48);
  margin-bottom: var(--px48);
  padding-left: 32px;
  background: url(../assets/img/icon/edit.png) no-repeat left top/24px;
  border-bottom: var(--border);
  display: block;
  font-size: 16px;
  line-height: 24px;
}

/* 价格曲线图 */
.kline-part {
  padding-bottom: 0;
}

.kline-part h2:first-of-type {
  margin-bottom: 16px;
}

.kline-part label {
  font-size: 16px;
}

/* 开发活跃度 */
.dev-active{
  margin-bottom: var(--px48);
  padding: 24px;
  border-radius: 4px;
  width: 48%;
  background: #fafafa;
}
.dev-active.border{
  border:var(--border);
  background: transparent;
}
.dev-active h2{
  font-weight: 400;
  font-family: Rubik-Regular;
  margin-top: 8px;
}
.dev-active.border h2{
  margin-bottom: 0;
}
.dev-active li:last-child h2{
  margin-bottom: 0;
}
.dev-active label{
  font-size: 14px;
  margin-left: 4px;
  font-family: Rubik-Medium;
}
.item-list a{
  display: block;
  color:var(--greyColor);
  margin-bottom: 12px;
}
.select-kline-type{
  border-bottom: var(--border);
  margin-bottom: var(--px48) ;
}
.about{
  margin-bottom: var(--px48);
}
.swap-link{
  margin-bottom: 32px;
}
.like-info{
  float: right;
  text-align: center;
  min-width: 80px;
  border-radius: 8px;
  padding: 8px;
  background: #F5F5F5;
  font-size: 12px;
  user-select: none;
  cursor: pointer;
}
.like-info h6{
  font:bold 14px/16px Rubik-Medium;
  margin-top: 4px;
}
.like-info.active{
  background: #fffbf0;
}
.voters-list a{
  display: block;
  color: var(--greyColor);
  margin-bottom: 12px;
}
.fsz-16{
  font-size: 16px;
}
h2 .fsz-16{
  margin-left: 8px;
}
.progress{
  height: 8px;
  border-radius: 4px;
  background: #f5f5f5;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
}
.progress>span{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 4px;
}
.sparkline-part{
  margin-top: 20px;
}
@media(max-width:768px){
  .about{
    border-bottom: var(--border);
    padding-bottom: var(--px48);
  }
  .float-left,
  .float-right {
    float: none;
    width: 100%;
    max-width: unset;
  }
  .basic-info .float-left{
    float: left;
    width: calc(100% - 90px);
  }
  .basic-info .logo img {
    width: 60px;
    height: 60px;
  }
  .basic-info {
    padding: 0 0 24px 70px;
  }
  .project-detail .dapp-title,
  .project-detail h2 {
    font-size: 16px;
    line-height: 24px;
  }
  .tip,
  .project-detail .dapp-shortdes,
  .project-detail .dapp-type label {
    font-size: 12px;
  }
  .project-detail .dapp-slogan {
    white-space: normal;
    font-size: 12px;
  }
  .detail-contact ul {
    display: flex;
    flex-wrap: wrap;
  }
  .detail-contact li {
    width: 50%;
    font-size: 14px;
  }
  .share-btn,.detail-contact .website{
    font-size: 14px;
    background-size: 16px;
    background-position: left 16px center;
    padding: 8px 16px 8px 40px;
  }
  .subscribe-update {
    padding: 16px;
  }
}
@media(max-width:500px){
  .flex-between{
    display: block;
  }
  .dev-active{
    width: 100%;
  }
}
</style>
